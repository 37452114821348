<template>
  <div>
    <v-container>
      <!-- <admin-login v-if="loginMode == 'Admin'" @mode-selected="setLoginMode"></admin-login> -->
      <agent-login ></agent-login>
      <!-- <login-mode v-if="loginMode == ''" @mode-selected="setLoginMode"></login-mode> -->
    </v-container>
  </div>
</template>

<script>
import AdminLogin from '@/components/AdminLogin.vue';
import AgentLogin from '../../components/AgentLogin.vue';
import LoginMode from '@/components/LoginMode.vue'
export default {
  data(){
    return {
      loginMode : "",
      
    }
  },
  components : {
    AdminLogin,
    AgentLogin,
    LoginMode
  },
  methods : {
    setLoginMode(mode){
      this.loginMode = mode
    }
  }
}
</script>