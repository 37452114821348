<template>
  <div class="">
    <v-layout row wrap class="">
      <v-flex xs12 md12 lg12 class="pa-5">
        <h1 class="f-alfa primary--text pl-5 pr-5">Schedules</h1>
      </v-flex>

      <v-flex xs12 class="pa-5 d-flex">
        <!-- <div>
          <v-date-picker v-model="dates" range></v-date-picker>
        </div> -->

        <div class="pl-5 pr-5" style="width: 100%">
          <div class="d-flex gap-2">
            <div class="pr-2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <p class="pa-0 ma-0">Date Range</p>
                  <v-text-field
                    v-model="dates"
                    single-line
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dates" range no-title scrollable>
                  <v-spacer></v-spacer>

                  <v-btn text color="primary" @click="menu2 = false">
                    OK
                  </v-btn>
                </v-date-picker>
                <!-- <v-date-picker
                    v-model="dates"
                    range
                    @input="menu2 = false"
                  ></v-date-picker> -->
              </v-menu>
              <!-- <v-date-picker v-model="dates" range></v-date-picker>
          <div class="grey darken-3 mt-3 pa-2" style="border-radius: 3px">
            {{ dates }}
          </div> -->
            </div>
            <div class="pr-2">
              <p class="pa-0 ma-0">Duration</p>
              <v-select
                :items="durations"
                label="Duration"
                single-line
                outlined
                v-model="duration"
              ></v-select>
            </div>
            <!-- <div class="pr-2">
              <p class="pa-0 ma-0">Day / Night</p>
              <v-select
                :items="types"
                label="Type"
                single-line
                outlined
                v-model="type"
              ></v-select>
            </div> -->
            <div class="pr-2">
              <p class="pa-0 ma-0">Start Time</p>
              <v-select
                single-line
                outlined
                :items="startTimes"
                v-model="startTime"
                label="Ex. 1000"
              ></v-select>
            </div>
            <div>
              <p class="pa-0 ma-0">End Time</p>
              <v-select
                single-line
                outlined
                :items="endTimes"
                v-model="endTime"
                label="Ex. 1600"
              ></v-select>
            </div>

            <div class="pl-2">
              <p class="pa-0 ma-0">Seats</p>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: 18pt;
                  height: 55px;
                  width: 200px;
                  font-weight: bold;
                  color: #fff;
                  background-color: #212121;
                  border-radius: 5px;
                  border: 1px solid #616161;
                "
              >
                <a
                  style="color: #fff; cursor: pointer; padding: 20px"
                  @click="decrementSeats"
                  ><span>-</span></a
                >
                <span
                  style="
                    padding-left: 30px;
                    padding-right: 30px;
                    border-left: 1px solid #424242;
                    border-right: 1px solid #424242;
                  "
                  >{{ seat }}</span
                >
                <a
                  style="color: #fff; cursor: pointer; padding: 20px"
                  @click="incrementSeats"
                  ><span>+</span></a
                >
              </div>
            </div>
          </div>

          <div class="pr-2 pt-5">
            <v-btn class="primary black--text" x-large @click="fetchSchedules"
              >Filter</v-btn
            >
            <v-btn x-large @click="clearFilters" class="ml-5">Clear</v-btn>
          </div>
          <div>
            <v-divider class="mt-5 mb-5"></v-divider>
          </div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <!-- <th class="text-left uppercase primary--text">Flight</th> -->
                  <th class="text-left uppercase primary--text">date</th>
                  <th class="text-left uppercase primary--text">Take off</th>
                  <th class="text-left uppercase primary--text">Duration</th>
                  <th class="text-left uppercase primary--text">Landing</th>
                  <th class="text-left uppercase primary--text">Seats</th>
                  <th class="text-left uppercase primary--text">
                    Net Amt (AED)
                  </th>
                  <th class="text-left uppercase primary--text">
                    P. Amt (AED)
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in schedules" :key="item._id">
                  <!-- <td>{{ item.ac_unique_code }}</td> -->
                  <td class="primary--text">
                    <b>{{ item.date }}</b>
                  </td>
                  <td>{{ item.flight_take_off }}</td>
                  <td>{{ item.duration }}</td>
                  <td>{{ item.landing_time }}</td>
                  <td>{{ item.seats_available }} / {{ item.seats }}</td>
                  <td>{{ item.net_amount }}</td>
                  <td class="primary--text">
                    <b>{{ item.net_amount * 0.8 }}</b>
                  </td>
                  <td>
                    <v-btn small class="primary" @click="openBooking(item)"
                      ><v-icon class="black--text" style="font-size: 18px"
                        >mdi-arrow-right</v-icon
                      ></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="pl-5 pr-5"></div>
      </v-flex>
      <v-flex xs12 md12 class="pa-5"> </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="800" persistent v-if="selectedSchedule">
      <v-card>
        <v-card-title class="text-h5 primary--text grey darken-3">
          Place Booking
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <tbody>
              <tr>
                <td>Date</td>
                <td>{{ selectedSchedule.date }}</td>
                <td>Flight Take off</td>
                <td>{{ selectedSchedule.flight_take_off }}</td>
              </tr>
              <tr>
                <td>Duration</td>
                <td>{{ selectedSchedule.duration }}</td>
                <td>Net Amount</td>
                <td>{{ selectedSchedule.net_amount }}</td>
              </tr>
              <tr>
                <td>Payable Amount</td>
                <td>{{ selectedSchedule.net_amount * 0.8 * seat }}</td>
                <td>Seats</td>
                <td>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      font-size: 18pt;
                      height: 55px;
                      width: 200px;
                      font-weight: bold;
                      color: #fff;
                      background-color: #212121;
                      border-radius: 5px;
                      border: 1px solid #616161;
                    "
                  >
                    <a
                      style="color: #fff; cursor: pointer; padding: 20px"
                      @click="decrementSeats"
                      ><span>-</span></a
                    >
                    <span
                      style="
                        padding-left: 30px;
                        padding-right: 30px;
                        border-left: 1px solid #424242;
                        border-right: 1px solid #424242;
                      "
                      >{{ seat }}</span
                    >
                    <a
                      style="color: #fff; cursor: pointer; padding: 20px"
                      @click="incrementSeats"
                      ><span>+</span></a
                    >
                  </div>
                  <!-- <v-select :items="seats" v-model="seat"></v-select> -->
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-divider class="mb-3"></v-divider>
          <v-layout row wrap>
            <v-flex xs12 md6 class="pa-2">
              <v-text-field
                label="Full Name"
                single-line
                outlined
                v-model="name"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <v-text-field
                label="Email"
                single-line
                outlined
                v-model="email"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <v-text-field
                label="Phone"
                single-line
                outlined
                v-model="phone"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <v-text-field
                label="Whatsapp"
                single-line
                outlined
                v-model="whatsapp"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="mt-3 mb-3">
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 md12 class="pa-2">
              <p class="pa-0 ma-0 mb-1">
                Enter 6 Digit OTP sent to you registered email address.
              </p>
              <v-text-field
                label="Enter 6 digit OTP"
                single-line
                outlined
                v-model="otp"
              >
                <template v-slot:append>
                  <v-btn @click="sendOtp" class="primary black--text">
                    Send OTP
                  </v-btn>
                </template>
              </v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <v-btn class="primary black--text" large @click="placeBooking"
                >Place Booking</v-btn
              >
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="dialog = false"> CLOSE </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { BASE_URL } from "@/config";
import Axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      whatsapp: "",
      selectedSchedule: null,
      seats: ["1", "2", "3", "4", "5"],
      seat: "1",
      dialog: false,
      menu2: false,
      filter: false,
      schedules: [],
      loading: false,
      durations: ["no filter",17, 22, 30],
      startTimes: [
        "0600",
        "0700",
        "0800",
        "0900",
        "1000",
        "1100",
        "1200",
        "1300",
        "1400",
        "1500",
        "1600",
        "1700",
      ],
      endTimes: [
        "0700",
        "0800",
        "0900",
        "1000",
        "1100",
        "1200",
        "1300",
        "1400",
        "1500",
        "1600",
        "1700",
        "1800",
      ],
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      duration: "no filter",
      type: "no filter",
      types: ["no filter", "day", "night"],
      startTime: "",
      endTime: "",
    };
  },
  mounted() {
    this.fetchSchedules();
  },
  computed: {
    getFilterButtonClass() {
      if (this.filter) {
        return "primary black--text";
      } else {
      }
    },
    ...mapGetters(["USER"]),
  },
  filters: {
    round(value) {
      return Math.round(value * 100) / 100; // Rounds off to two decimal places
    },
  },
  methods: {
    async sendOtp(){
      let url = BASE_URL + "/agent/send-otp";
        let payload = {
          email: this.USER.agent.email,
          context: "booking",
        };
        let { data } = await Axios.post(url, payload);
        this.$toastr.s(
          "Please check your registered email id for your OTP",
          "OTP Sent"
        );
    },
    async placeBooking() {
      let url = BASE_URL + "/agent/verify-otp";
      let payload = {
        email: this.USER.agent.email,
        otp: this.otp,
      };
      let { data } = await Axios.post(url, payload);
      if (data.success) {
        let payload = {
          mobile_number: this.phone,
          name: this.name,
          email: this.email,
          whatsapp: this.whatsapp,
          date: this.selectedSchedule.date,
          time: this.selectedSchedule.time,
          seats: this.seat,
          status: "confirmed",
          channel: "AGENT",
          amount: this.selectedSchedule.net_amount * 0.8 * this.seat,
          ac_unique_code: this.selectedSchedule.ac_unique_code,
          duration: this.selectedSchedule.duration,
          agent: this.USER.agent._id,
        };
        let url = BASE_URL + "/booking/agent";
        let { data } = await Axios.post(url, payload);
        console.log(JSON.stringify(data))
        this.$toastr.s("New booking created successfully", "Booking Placed");
        this.$router.push({
          name: "AgentBookingDetail",
          params: { id: data.booking.booking_id },
        });
      } else {
        this.$toastr.e("Please check your OTP", "OTP Verification Failed");
      }
    },
    incrementSeats() {
      if (this.seat < 5) {
        this.seat = (parseInt(this.seat) + 1).toString();
      }
    },
    decrementSeats() {
      if (this.seat > 1) {
        this.seat = (parseInt(this.seat) - 1).toString();
      }
    },
    openBooking(schedule) {
      this.$router.push({name : "AgentBooking",params : {id : schedule._id},query : {seat : this.seat}})
      // this.selectedSchedule = schedule;
      // this.dialog = true;
    },
    roundValue(value) {
      return Math.round(value * 100) / 100; // Rounds off to two decimal places
    },
    async fetchSchedules() {
      var q_params = "?";
      if (this.dates.length == 1) {
        q_params += "date=" + this.dates[0];
      } else {
        var _d = this.getStartEndDate();
        q_params += "startDate=" + _d.startDate + "&endDate=" + _d.endDate;
      }
      if (this.startTime && this.endTime) {
        q_params += "&startTime=" + this.startTime + "&endTime=" + this.endTime;
      }
      if (this.duration != "no filter") {
        q_params += "&duration=" + this.duration;
      }
      if (this.type != "no filter") {
        q_params += "&type=" + this.type;
      }
      q_params += "&seats="+this.seat
      this.$store.dispatch("SET_PROGRESS", true);
      let url = BASE_URL + "/schedule" + q_params;
      let { data } = await Axios.get(url);
      this.schedules = data.schedules;
      this.$toastr.s("Apply filters to tweak your data", "SCHEDULES LOADED");
      this.$store.dispatch("SET_PROGRESS", false);
    },
    getStartEndDate() {
      var date1 = new Date(this.dates[0]);
      var date2 = new Date(this.dates[1]);
      var startDate = date1 < date2 ? date1 : date2;
      var endDate = date1 < date2 ? date2 : date1;
      return {
        startDate: startDate.toISOString().split("T")[0], // Format as 'YYYY-MM-DD'
        endDate: endDate.toISOString().split("T")[0], // Format as 'YYYY-MM-DD'
      };
    },
    clearFilters() {
      (this.dates = [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ]),
        (this.duration = "no filter");
      this.type = "no filter";
      this.startTime = "";
      this.endTime = "";
      this.$toastr.s("Filters reset", "CLEARED");
      this.fetchSchedules();
    },
  },
};
</script>

<style>
.v-input__append-inner {
  margin-top: 10px !important;
}
</style>