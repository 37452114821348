<template>
  <div>
    <agent-reports v-if="this.account.payment_mode == 'Credit'"></agent-reports>
    <agent-reports-direct-payment v-if="this.account.payment_mode == 'Direct Payment'"></agent-reports-direct-payment>
    <div class="pl-1 pr-1">
      <v-tabs v-model="tab" class="mb-5">
        <v-tab>Schedule</v-tab>
        <v-tab>Booking</v-tab>
        <v-tab>Transactions</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <agent-schedules></agent-schedules>
        </v-tab-item>
        <v-tab-item>
          <agent-bookings></agent-bookings>
        </v-tab-item>
        <v-tab-item>
          <agent-payment></agent-payment>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@/config";
import Axios from "axios";
import { mapGetters } from "vuex";
import AgentBookings from "../../components/AgentBookings.vue";
import AgentSchedules from "../../components/AgentSchedules.vue";
import AgentPayment from "../../components/AgentPayment.vue";
import AgentReports from "../../components/AgentReports.vue";
import AgentReportsDirectPayment from '../../components/AgentReportsDirectPayment.vue';

export default {
  data() {
    return {
      account: {},
      tab: "",
    };
  },
  components: {
    AgentBookings,
    AgentPayment,
    AgentSchedules,
    AgentReports,
    AgentReportsDirectPayment,
  },
  computed: {
    ...mapGetters(["USER"]),
  },
  mounted() {
    this.fetchAccount();
  },
  methods: {
    async fetchAccount() {
      let url = BASE_URL + "/agent/" + this.USER.admin.profile;
      let { data } = await Axios.get(url);
      this.account = data;
    },
  },
};
</script>