import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueToastr from "vue-toastr";
Vue.use(VueToastr); 
Vue.use(require('vue-moment'))
import excel from 'vue-excel-export'
Vue.use(excel)
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.config.productionTip = false

Vue.filter('numberFormat', function (value) {
  if (!value) return '';
  value = parseFloat(value);
  return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
