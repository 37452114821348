<template>
  <v-layout row wrap class="d-flex justify-center mt-10">
    <v-flex xs12 md8 lg8 class="card-bg pa-10 border-radius-10">
     
      <h1 class="primary--text mt-5">Welcome to IE</h1>
      <p class="mb-5">
        Login with your email, password & 6-digit OTP sent to your email.
      </p>
      <v-text-field
        label="Email"
        single-line
        outlined
        type="email"
        v-model="email"
      >
        <template v-slot:append-outer>
          <v-btn
            @click="sendOTP"
            v-bind="attrs"
            v-if="!sending_otp"
            v-on="on"
            outlined
            color="primary"
            style="height: 55px; margin-top: -18px"
          >
            <v-icon left> mdi-email-outline </v-icon>
            SEND OTP
          </v-btn>
          <v-progress-circular
      indeterminate
      color="primary"
      v-else
    ></v-progress-circular>
        </template>
      </v-text-field>
      <v-text-field
        label="Password"
        single-line
        outlined
        type="password"
        v-model="password"
        class="mt-5"
      ></v-text-field>
      <v-text-field
        label="6-Digit OTP"
        single-line
        outlined
        type="number"
        v-model="otp"
        class="mt-5"
      ></v-text-field>
      <div class="d-flex justify-space-between align-center mt-5">
        <v-btn class="primary pl-10 pr-10 black--text" x-large @click="login"
          >Login</v-btn
        >
        <a>Forgot Password ?</a>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import Axios from "axios";
import { BASE_URL } from "@/config";
export default {
  data() {
    return {
      email: "",
      password: "",
      otp: "",
      sending_otp : false
    };
  },
  methods: {
    selectMode(mode) {
      this.$emit("mode-selected", mode);
    },
    async sendOTP() {
      this.sending_otp = true
      if (this.email != "") {
        let url = BASE_URL + "/agent/send-otp";
        let payload = {
          email: this.email,
          context: "login",
        };
        let { data } = await Axios.post(url, payload);
        this.$toastr.s(
          "Please check your registered email id for your OTP",
          "OTP Sent"
        );
        this.sending_otp = false
      } else {
        this.$toastr.e(
          "Please enter your email to send OTP",
          "ENTER YOUR EMAIL"
        );
        this.sending_otp = false
      }
    },
    async login() {
      this.$store.dispatch("SET_PROGRESS", true);
      {
        let url = BASE_URL + "/agent/verify-otp";
        let payload = {
          email: this.email,
          otp: this.otp,
        };
        let { data } = await Axios.post(url, payload);
        if (data.success) {
          let url = BASE_URL + "/admin/login";
          let payload = {
            email: this.email,
            password: this.password,
          };
          if (this.email != "" && this.password != "") {
            let { data } = await Axios.post(url, payload);
            if (data.success) {
              this.$toastr.s(
                "LOGGED IN",
                "Your are successfully logged in to Ingenious Engine"
              );
              this.$store.dispatch("SET_USER", data);
              this.$router.push("/");
            } else {
              this.password = ""
              this.$toastr.e(
                "LOGIN FAILED",
                "Please check your email and password"
              );
            }
            this.$store.dispatch("SET_PROGRESS", false);
          } else {
            this.$toastr.e(
              "Please submit your email and password to login",
              "Missing Data"
            );
            this.$store.dispatch("SET_PROGRESS", false);
          }
        } else {
          this.password = "";
          this.otp = "";
          this.$toastr.e(
            "Please submit correct OTP to login",
            "OTP validation failure"
          );
          this.$store.dispatch("SET_PROGRESS", false);
        }
      }
    },
  },
};
</script>

<style scoped>
.card-bg {
  background: #212121;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-10 {
  border-radius: 10px;
}
</style>