import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/accounts',
    name : 'Accounts',
    component: () => import('../views/user-management/Accounts')
  },
  {
    path : '/add-account',
    name : 'AddAccount',
    component: () => import('../views/user-management/AddAccount')
  },
  {
    path : '/view-account/:id',
    name : 'ViewAccount',
    component : () => import('../views/user-management/ViewAccount')
  },
  {
    path : '/integrations',
    name : 'Integrations',
    component : () => import('../views/integrations/Integrations')
  },
  {
    path : '/integration/add',
    name : 'AddIntegration',
    component : () => import('../views/integrations/AddIntegrations')
  },
  {
    path : '/schedules',
    name : 'Schedules',
    component : () => import('../views/schedule/Schedules')
  },
  {
    path : '/flight-status',
    name : 'FlightStatus',
    component : () => import('../views/flight-status/FlightStatus')
  },
  {
    path : '/bookings',
    name : 'Bookings',
    component : () => import('../views/bookings/Bookings')
  },
  {
    path : '/bookings/details/:id',
    name : 'BookingDetail',
    component : () => import('../views/bookings/BookingDetail')
  },
  {
    path : '/support/create-case',
    name : 'CreateCase',
    component : () => import('../views/support/CreateCase')
  },
  {
    path : '/support/cases',
    name : 'Cases',
    component : () => import('../views/support/Cases')
  },
  {
    path : '/support/schedules',
    name : 'SupportSchedules',
    component : () => import('../views/support/Schedules')
  },
  {
    path : '/support/place-booking/:id',
    name : 'SupportBooking',
    component : () => import('../views/support/PlaceBooking')
  },
  {
    path : '/support/case-detail/:id',
    name : 'CaseDetail',
    component : () => import('../views/support/CaseDetail')
  },
  {
    path : '/master-listings/add-helicopter',
    name : 'AddHelicopter',
    component : () => import('../views/master-listings/AddHelicopter')
  },
  {
    path : '/master-listings',
    name : 'MasterListings',
    component : () => import('../views/master-listings/MasterListings')
  },
  {
    path : '/master-listings/list-helicopter',
    name : 'ListHelicopter',
    component : () => import('../views/master-listings/ListHelicopters')
  },
  {
    path : '/master-listings/view-helicopter/:id',
    name : 'ViewHelicopter',
    component : () => import('../views/master-listings/ViewHelicopter')
  },
  {
    path : '/reports',
    name : 'Reports',
    component : () => import('../views/bookings/Reports')
  },
  {
    path : '/agents',
    name : 'Agents',
    component : () => import('../views/agents/Agents')
  },
  {
    path : '/agents/add-agent',
    name : 'AddAgent',
    component : () => import('../views/agents/AddAgent'),
    
  },
  {
    path : '/agents/view-agent/:id',
    name : 'ViewAgent',
    component : () => import('../views/agents/ViewAgent')
  },
  {
    path : '/agents/edit-agent/:id',
    name : 'EditAgent',
    component : () => import('../views/agents/EditAgent')
  },
  {
    path : '/agents/bookings/details/:id',
    name : 'AgentBookingDetail',
    component : () => import('../views/agents/AgentBookingDetail')
  },
  {
    path : '/agents/bookings/details/edit/:id',
    name : 'EditAgentBookingDetail',
    component : () => import('../views/agents/EditBookingDetail')
  },
  {
    path : '/promotions',
    name : 'Promotions',
    component : () => import('../views/promotions/Promotions')
  },
  {
    path : '/promotions/vouchers',
    name : 'Vouchers',
    component : () => import('../views/promotions/Vouchers')
  },
  {
    path : '/promotions/add-voucher',
    name : 'AddVoucher',
    component : () => import('../views/promotions/AddVoucher'),
  },
  {
    path : '/agents/schedules',
    name : 'AgentSchedules',
    component : () => import('../views/agents/Schedules.vue'),
  },
  {
    path : '/agents/book/:id',
    name : 'AgentBooking',
    component : () => import('../views/agents/PlaceBooking.vue'),
  },
  {
    path : '/booking-confirmation',
    name : 'BookingConfirmation',
    component : () => import('../views/agents/BookingConfirmation.vue')
  },
  {
    path : '/booking-confirmation-credit',
    name : 'BookingConfirmation',
    component : () => import('../views/agents/BookingConfirmationCredit.vue')
  }
  
  
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (Vue.$store.state.USER && Vue.$store.state.USER.admin) {
      next(); // Allow access
    } else {
      next('/login');
    }
  } else {
    next(); 
  }
});

export default router
