<template>
  <div class="">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="pa-5">
        <h1 class="f-alfa primary--text pl-5 pr-5">Booking</h1>
      </v-flex>
      <v-flex xs12 class="pl-5 pr-5">
        <v-layout row wrap class="d-flex justify-space-between align-center">
          <v-flex xs12 class="pa-5 d-flex">
            
              <div class="d-flex gap-2" style="justify-content: space-between;width: 100%;">
                <div class="d-flex">
                  <div class="pr-2">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <p class="pa-0 ma-0">Date Range</p>
                        <v-text-field
                          v-model="dates"
                          single-line
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dates"
                        range
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="pl-2 pr-2">
                    <p class="pa-0 ma-0">Status</p>
                    <v-select
                      :items="statuses"
                      label="Status"
                      single-line
                      outlined
                      v-model="status"
                    ></v-select>
                  </div>

                  <div class="pa-5 pt-6">
                    <v-btn
                      class="primary black--text"
                      x-large
                      @click="fetchBookings"
                      >Filter</v-btn
                    >
                    <v-btn x-large @click="clearFilters" class="ml-5"
                      >Clear</v-btn
                    >
                  </div>
                </div>
                <div class="pt-5">
                  <v-btn class="" x-large>
                    <export-excel :data="bookings">
                    Download Data
                  </export-excel>
                  </v-btn>
                  
                </div>
              </div>
            
            <div class="pl-5 pr-5"></div>
          </v-flex>
          <!-- <v-flex xs8 md6 lg4>
              <v-text-field
                single-line
                outlined
                v-model="searchText"
                label="Type to search.."
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 md6 lg8 class="text-right">
              <export-excel :data="bookings">
                <v-btn x-large class="primary black--text">Export .xls</v-btn>
              </export-excel>
            </v-flex> -->
        </v-layout>
      </v-flex>
      <v-flex xs12 md12 class="pl-10 pr-10">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <!-- <th class="text-left uppercase primary--text">
                    Ac Unique Code
                  </th> -->
                <th class="text-left uppercase primary--text">Booking ID</th>
                <th class="text-left uppercase primary--text">date</th>
                <th class="text-left uppercase primary--text">time</th>
                <th class="text-left uppercase primary--text">seats</th>
                <th class="text-left uppercase primary--text">Name</th>
                <!-- <th class="text-left uppercase primary--text">Email</th> -->
                <!-- <th class="text-left uppercase primary--text">Mobile</th> -->
                <th class="text-left uppercase primary--text">Amount</th>
                <th class="text-left uppercase primary--text">Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in bookings" :key="item._id">
                <!-- <td>{{ item.ac_unique_code }}</td> -->
                <td>{{ item.booking_id }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.time }}</td>
                <td>{{ item.seats }}</td>
                <td>{{ item.name }}</td>
                <!-- <td>{{ item.email }}</td> -->
                <!-- <td>{{ item.mobile_number }}</td> -->
                <td>{{ item.net_amount | numberFormat }}</td>
                <td
                  style="text-transform: uppercase"
                  :class="getStatusColor(item.status)"
                >
                  {{ item.status }}
                </td>
                <td>
                  <v-btn @click="viewBooking(item.booking_id)"
                    ><v-icon class="primary--text">mdi-magnify</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="mt-10 text-center pt-10" v-if="bookings.length == 0">
          There are no bookigs on the date selected.
        </p>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import Axios from "axios";
import { BASE_URL } from "@/config";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      bookings: [],
      filter: false,
      status: "",
      statuses: [
        "Abandoned",
        "Confirmed",
        "Cancelled",
        "Pending Payment Confirmation",
      ],
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
    };
  },
  computed: {
    ...mapGetters(["USER"]),
  },
  mounted() {
    this.fetchBookings();
  },
  methods: {
    getStatusColor(status) {
      if (status.toLowerCase() == "confirmed") {
        return "green--text";
      } else if (status.toLowerCase() == "cancelled") {
        return "red--text";
      }
    },
    async fetchBookings() {
      var q_params = "?agent_id=" + this.USER.admin.profile;

      if (this.dates.length == 1) {
      } else {
        var _d = this.getStartEndDate();
        q_params += "&startDate=" + _d.startDate + "&endDate=" + _d.endDate;
      }
      if (this.status != "") {
        q_params += "&status=" + this.status;
      }
      const headers = {
        Authorization:
          "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ0ODMyYjliYWFmMDVkMzNhNDEwYTMiLCJjaGFubmVsIjoiQm9va2luZy5jb20iLCJjcmVhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJ1cGRhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJfX3YiOjAsImlhdCI6MTY5OTE3MDEzOX0.iS7s7eHzP_yTGD0WIFYEoFSo9HvAuVfhawHkRpv2bls",
      };
      let url = BASE_URL + "/booking/agent/list" + q_params;
      let { data } = await Axios.get(url, { headers });
      this.bookings = data;
    },
    viewBooking(id) {
      this.$router.push({ name: "AgentBookingDetail", params: { id: id } });
    },
    getStartEndDate() {
      var date1 = new Date(this.dates[0]);
      var date2 = new Date(this.dates[1]);
      var startDate = date1 < date2 ? date1 : date2;
      var endDate = date1 < date2 ? date2 : date1;
      return {
        startDate: startDate.toISOString().split("T")[0], // Format as 'YYYY-MM-DD'
        endDate: endDate.toISOString().split("T")[0], // Format as 'YYYY-MM-DD'
      };
    },
    clearFilters() {
      (this.dates = [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ]),
        (this.duration = "no filter");
      this.status = "";
      this.$toastr.s("Filters reset", "CLEARED");
      this.fetchBookings();
    },
  },
};
</script>