<template>
  <v-app>
    <v-app-bar app color="black" dark height="100">
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          height="100"
          @click="goHome"
          style="cursor: pointer"
        />
      </div>
      <v-spacer></v-spacer>
      <img src="./assets/ac-logo.png" style="height: 60px" />
      <v-progress-linear v-show="PROGRESS" :indeterminate="true" slot="extension" class="ma-0" color="primary darken-1" background-color="grey" height="2"></v-progress-linear> 
    </v-app-bar>
    <v-main>
      <v-container class="mt-5" style="padding-bottom: 150px">
        <div v-if="USER != null">
          <router-view v-if="USER.admin != undefined || USER.agent != undefined" />
          <login v-else />
        </div>
        <login v-else />
      </v-container>
      <div class="vertical-text d-none d-md-flex" style="font-size: 10pt">
        Powered By <span class="primary--text" style="margin-top: 5px;"> Air Chateau</span>
      </div>
      <div
        style="position: fixed; bottom: 20px; right: 10px"
        class="d-none d-md-flex"
      >
        <div>
          <img src="./assets/pay-by.png" style="width: 64px" /> <br />
          <img src="./assets/yellow-ai.png" style="width: 64px" /> <br />
          <img src="./assets/zoho.png" style="width: 64px" /> <br />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Login from "./views/auth/Login";
export default {
  name: "App",

  data: () => ({
    //
  }),
  components: {
    Login,
  },
  computed: {
    ...mapGetters(["USER","PROGRESS"]),
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
html,
body {
  background: #000 !important;
  height: 100%;
}
.v-application {
  font-family: "Blinker", sans-serif !important;
}
p {
  font-family: "Blinker", sans-serif !important;
}
h1 {
  font-family: "Blinker", sans-serif !important;
}
.display-1 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-1 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-2 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-3 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-4 {
  font-family: "Blinker", sans-serif !important;
}
.theme--dark.v-application {
  background: #000;
}
.toast-title {
  font-family: "Blinker" !important;
  font-weight: 700;
}
.toast-message {
  font-family: "Blinker" !important;
}
.f-alfa {
  font-family: "Alfa Slab One", serif !important;
  letter-spacing: 1px;
  font-weight: 500;
}
.row {
  padding: 0px !important;
  margin: 0px !important;
}

.v-text-field__details {
  display: none !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.fw-bold {
  font-weight: bold !important;
}
.vertical-text {
  writing-mode: vertical-rl; /* This property makes text vertical */
  transform: rotate(
    180deg
  ); /* Rotate the text 180 degrees to make it upright */
  position: fixed;
  bottom: 20px;
  left: 20px;
}

@media (min-width: 960px) {
  .container {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media only screen and (max-width: 575.98px) {
  /* Styles for extra small screens */
  .f-alfa{
    font-size: 14pt !important;
  }
}

.v-toolbar__extension{
  height: 5px !important;
}

header{
  height: auto !important;
}

/* h1,h2,h3,h4,h5,h6,body,html,a,.v-application,.display-2 */
</style>
