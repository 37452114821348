<template>
  <div class="home">
    <main-menu v-if="USER.admin.role == 'Super Admin'"></main-menu>
    <agent-dashboard v-if="USER.admin.role == 'Agent'"></agent-dashboard>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import MainMenu from '../components/MainMenu.vue';
import AgentDashboard from '../views/agents/AgentsDashboard.vue';
import AgentBookings from '../components/AgentBookings.vue';
import AgentPayment from '../components/AgentPayment.vue';
export default {
  name: 'Home',
  components: {
    MainMenu,
    AgentDashboard,
    AgentBookings,
    AgentPayment,
  },
  computed: {
    ...mapGetters(['USER'])
  },
  
}
</script>

<style scoped>
.menu-item {
  cursor: pointer;
  border: 2px solid #ba9d73;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: 5px 5px 10px rgba(186, 157, 115, 1);
}
.menu-item-disabled {
  cursor: not-allowed;
  border: 2px solid #ba9d73;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: 5px 5px 10px rgba(186, 157, 115, 1);
}
.fw-bold{
  font-weight: 700 !important;
}
h3{
  margin: 0px !important;
  padding: 0px !important;
}
</style>
