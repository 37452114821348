<template>
  <div style="" class="mb-10">
    <v-layout row wrap class="primary--text">
      <v-flex xs10 class="mb-5 pa-1">
        <h3 class="primary--text">Welcome back, {{ USER.admin.name }}</h3>
        <h1 class="f-alfa primary--text">AGENT</h1>
      </v-flex>
      <v-flex xs2 class="d-flex align-center justify-end pa-1">
        <v-btn class="accent--text" @click="logout">Logout</v-btn>
      </v-flex>
      <v-flex xs12 md4 class="pa-1">
        <div
          style="
            background-color: #212121;
            border-radius: 5px;
            display: flex;
            align-items: center;
          "
          class="pa-4"
        >
          <v-icon style="font-size: 48px" class="primary--text mr-5"
            >mdi-file-chart-outline</v-icon
          >
          <div>
            <h2>Bookings</h2>
            <p>{{ total_bookings }}</p>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-1">
        <div
          style="background-color: #212121; border-radius: 5px; display: flex"
          class="pa-4"
        >
          <v-icon style="font-size: 48px" class="primary--text mr-5"
            >mdi-currency-usd</v-icon
          >
          <div>
            <h2>Total Revenue</h2>
            <p>{{ total_revenue | numberFormat }} AED</p>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-1">
        <div
          style="background-color: #212121; border-radius: 5px; display: flex"
          class="pa-4"
        >
          <v-icon style="font-size: 48px" class="primary--text mr-5"
            >mdi-invoice-list-outline</v-icon
          >
          <div>
            <h2>Payment Due</h2>
            <p>{{ amount_due | numberFormat }} AED</p>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-1">
        <div
          style="background-color: #212121; border-radius: 5px; display: flex"
          class="pa-4"
        >
          <v-icon style="font-size: 48px" class="primary--text mr-5"
            >mdi-calendar-clock-outline</v-icon
          >
          <div>
            <h2>Payment Due Date</h2>
            <p>{{ next_payment_date | moment("dddd, MMMM Do YYYY") }}</p>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-1">
        <div
          style="background-color: #212121; border-radius: 5px; display: flex"
          class="pa-4"
        >
          <v-icon style="font-size: 48px" class="primary--text mr-5"
            >mdi-calendar-check-outline</v-icon
          >
          <div>
            <h2>Last Payment Date</h2>
            <p>--</p>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-1">
        <div
          style="background-color: #212121; border-radius: 5px; display: flex"
          class="pa-4"
        >
          <v-icon style="font-size: 48px" class="primary--text mr-5"
            >mdi-invoice-text-check-outline</v-icon
          >
          <div>
            <h2>Last Paid Amount</h2>
            <p>{{ last_payment_amount | numberFormat }} AED</p>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { BASE_URL } from "@/config.js";
import Axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      bookings: [],
      total_bookings: 0,
      amount_due: 0,
      total_revenue: 0,
      last_payment_date: "",
      last_payment_amount: 0,
      next_payment_date: "",
    };
  },
  computed: {
    ...mapGetters(["USER"]),
  },
  mounted() {
    this.fetchBookings();
  },
  methods: {
    logout() {
      this.$store.dispatch("SET_USER", null);
      this.$toastr.s("LOGGED OUT", "Logged out of Ingenious Engine");
      this.$router.push("/");
    },
    async fetchBookings() {
      const headers = {
        Authorization:
          "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ0ODMyYjliYWFmMDVkMzNhNDEwYTMiLCJjaGFubmVsIjoiQm9va2luZy5jb20iLCJjcmVhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJ1cGRhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJfX3YiOjAsImlhdCI6MTY5OTE3MDEzOX0.iS7s7eHzP_yTGD0WIFYEoFSo9HvAuVfhawHkRpv2bls",
      };
      let url =
        BASE_URL + "/booking/agent/list?agent_id=" + this.USER.admin.profile;
      let { data } = await Axios.get(url, { headers });
      this.bookings = data;
      this.total_bookings = this.bookings.length;
      const confirmedBookings = this.bookings.filter(
        (booking) => booking.status === "Confirmed"
      );
      this.amount_due = Math.round(
        confirmedBookings.reduce((total, booking) => total + booking.net_amount, 0)
      );
      this.total_revenue = this.amount_due;
      this.next_payment_date = this.USER.agent.nextPaymentDate;
    },
  },
};
</script>
